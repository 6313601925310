@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply mb-fixed-bottom text-gray-800;
  }
  button {
    @apply focus:outline-none;
  }
  input {
    @apply focus:outline-none;
  }
  textarea {
    @apply focus:outline-none;
  }
}
